<!--
 * @Description: 用户管理
 * @Date: 2019-12-23 11:41:57
 * @LastEditTime: 2020-09-16 16:46:55
 * @LastEditors: 矢车
 -->
<template>
  <div id='user'>
    <!--    <p class="bread-crumbs">
      <a-icon v-if="isshowgoback" class="back-btn" type="rollback" @click="$router.go(-1)" />
      {{crumbsStr}}
    </p>-->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'user',
    data () {
      return {
        isshowgoback: false,
        crumbs:[]
      }
    },
    computed:{
      crumbsStr(){
        return this.crumbs.join(' / ')
      }
    },
    created () {
      const currentRouter = this.$router.currentRoute
      // 初次渲染面包屑
      this.renderNav(currentRouter)

      // 监听路由变化，修改顶部面包屑文字
      this.$watch('$route', to => {
        this.renderNav(to)
      })
    },
    methods: {
      renderNav (currentRouter) {
        const route = this.$store.state.user.routes;
        this.route = route.find(item => item.path === '/admin').children.find(item => item.path === 'operate')
        const subPath = this.$route.path.split('/')[3];
        if(this.$route.path.split('/').length>4){
          this.isshowgoback=true;
        }else{
          this.isshowgoback=false;
        }
        this.subRouteName = this.route.children.find(item => item.path === subPath).name
        if(currentRouter.meta.customCrumbs){
          this.crumbs = currentRouter.meta.customCrumbs
        }else{
          this.crumbs = [this.route.name,this.subRouteName]
        }
      }
    }
  }
</script>

<style lang='less' scoped>
  #user {
    background: #ffffff ;
    padding: 10px;
    .bread-crumbs {
      font-size: 12px;
      color: #666;
      padding: 8px 10px;
      background: #f0f0f0;
      margin-bottom: 10px;
    }
    .back-btn {
      padding: 2px;
      border: 1px solid #666;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
</style>
